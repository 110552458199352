<template>
  <v-form ref="form">
    <v-layout column>
      <DefaultSelect
        class="mt-4"
        :value.sync="medicalPlace.organization_cell"
        label="Typ komórki organizacyjnej"
        placeholder="Wybierz typ komórki organizacyjnej..."
        :items="items"
        clearable
        required
        infoText="Wybierz typ komórki organizacyjnej, z której będą wysyłane dane do P1 np. komórka."
      ></DefaultSelect>
      <LabelTextField
        :value.sync="medicalPlace.rpwdl"
        label="Numer RPWDL"
        placeholder="Wpisz numer RPWDL..."
        :rules="requiredRPWDL"
        required
      >
        <InfoButton
          slot="header-items"
          text="Numer możesz sprawdzić w wyszukiwarce RPWDL 1.0 <a href='https://rpwdl.ezdrowie.gov.pl' target='blank'>https://rpwdl.ezdrowie.gov.pl</a> lub RPWDL 2.0 <a href='https://rpwdl2.ezdrowie.gov.pl' target='blank'>https://rpwdl2.ezdrowie.gov.pl/wyszukiwarka</a> oraz we wniosku na swoim koncie RPWDL."
        ></InfoButton>
      </LabelTextField>
      <v-layout wrap>
        <LabelTextField
          :value.sync="medicalPlace.departmental_code"
          label="Kod resortowy"
          placeholder="Wpisz kod resortowy..."
          :rules="requiredInteger"
          required
        >
        </LabelTextField>
        <LabelTextField
          :value.sync="medicalPlace.regon"
          :rules="requiredInteger"
          label="REGON (14-znakowy)"
          placeholder="Wpisz REGON..."
          required
        >
        </LabelTextField>
      </v-layout>
      <v-layout wrap>
        <LabelTextField
          v-if="visibleDistChamNum"
          :value.sync="medicalPlace.dist_chamb_num"
          label="Numer izby okręgowej"
          placeholder="Wpisz numer izby okręgowej..."
          :rules="requiredInteger"
          required
        >
        </LabelTextField>
      </v-layout>
    </v-layout>
  </v-form>
</template>

<script>
import Validators from "@/mixins/Validators";

export default {
  mixins: [Validators],
  props: {
    medicalPlace: {},
  },
  data() {
    return {
      items: [
        { value: "J", text: "Jednostka" },
        { value: "K", text: "Komórka" },
      ],
      checked: this.medicalPlace.office == "none",
    };
  },
  watch: {
    checked() {
      if (this.checked) {
        this.medicalPlace.office = "none";
      } else {
        delete this.medicalPlace.office;
      }
    },
  },
  computed: {
    visibleDistChamNum() {
      return (
        this.medicalPlace &&
        ["L", "P"].includes(this.medicalPlace.organization_cell)
      );
    },
    visibleRegon() {
      return (
        this.medicalPlace &&
        ["O", "K", "J"].includes(this.medicalPlace.organization_cell)
      );
    },
    visibleDepCode() {
      return (
        this.medicalPlace &&
        ["O", "K", "J"].includes(this.medicalPlace.organization_cell)
      );
    },
    visibleTeryt() {
      return (
        this.medicalPlace &&
        ["L", "P", "F"].includes(this.medicalPlace.organization_cell)
      );
    },
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>